#root{
    @media (min-width: 992px) {
      height:100vh;
      overflow: auto;
      display: flex;
      align-items: center;
    }
  }

.App {


  @media (min-width: 992px) {
    .form-section{
      padding-left: 70px;
    }
  }
}
.App.container{
  max-width: 960px;
  max-height: 100vh;
  margin: 0 auto;
  .post-payment-messages{
    @media (min-width: 992px) {
      position:fixed;
      top: 1rem;
      left:1rem;
    }
    @media (max-width: 660px) {
     width:100%;
     text-align: center;
     margin-top: 2rem;
    }
  
  
  }

}
