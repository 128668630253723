.payment-form {
    // flex: 0 1 50%;
    // width: 50% !important;
    box-shadow: -17px 0px 20px -25px #ccc;

    .buttonAnddividerContainer {
        opacity: 1;
        display: block;
    }

    .divider {
        .divider-text {
            position: absolute;
            left: 50%;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            padding: 0 8px;
            white-space: nowrap;
            background-color: #fff;
            top: 1.2rem;
            font-size: 14px;
        }

        padding-top: 16px;
        position: relative;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        overflow: hidden;
        hr {
            width: 100%;
            height: 1px;
            border: none;
            background-color: rgba(26, 26, 26, .5);
            box-sizing: initial;
            overflow: visible;
        }
    }



    .form-section {
        input,textarea,select {
            border-radius: 6px;
            padding: 8px 12px;
            color: rgba(26, 26, 26, .9);
            line-height: 1.5;
            border: 0;
            transition: box-shadow .08s ease-in, color .08s ease-in, filter 50000s;
            background: #fff;
            box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgb(0 0 0 / 7%), 0 1px 1.5px 0 rgb(0 0 0 / 5%);
            &:disabled{
                background-color: #eeeded;
            }
        }
        select{
            width: 100%;
        }
        input:focus,textarea:focus {
            z-index: 2;
            outline: none;
            box-shadow: 0 0 0 1px rgb(50 151 211 / 30%), 0 1px 1px 0 rgb(0 0 0 / 7%), 0 0 0 4px rgb(50 151 211 / 30%);
        }
        label {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: #3c4257;
            margin-bottom: 5px;
        }
        .needs-validation {
            div {
                padding: 7px 0;
            }
        }
        #errorMessage {
            color: #cd3d63;
            font-size: 11px;
        }
        .description {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: #3c4257;
        }
        .NextButton {
            background-color: #0075d4;
        }
    }

    input[type="number"]::-webkit-outer-spin-button, 
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }
    .buttonIcon{
        border-radius: 6px;
        padding: 8px 12px;
        color: rgba(26, 26, 26, .9);
        line-height: 1.5;
        //border: 0;
        transition: box-shadow .08s ease-in, color .08s ease-in, filter 50000s;
        background: #fff;
        box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgb(0 0 0 / 7%), 0 1px 1.5px 0 rgb(0 0 0 / 5%);
        &:disabled {
            background-color: #eeeded;
        }
        width: 47%;
        height: 60px;
        text-align: left;
    }
    .RightBox{
        float: right;
    }
    .PaddingIcon{
        //margin-left: 10px;
        text-align: center;
        //background-color: #cd3d63;

        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        font-size: 12px;
    }
    .activeMethod{
        outline: none;
        box-shadow: 0 0 0 1px rgb(50 151 211 / 30%), 0 1px 1px 0 rgb(0 0 0 / 7%), 0 0 0 4px rgb(50 151 211 / 30%);
        font-weight: bold;
        border-width: 1px;
        border-color: #000000;
    }
    .inActiveNoborder{
        border-width: 0px;
    }
}
