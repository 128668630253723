.App-Footer {
  margin: 12px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  background-color: inherit;
  line-height: 16px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;

  .Link {
    span {
      color: #8792a2;
      font-size: 12px;
      font-weight: 400;
      padding: 0 10px;
    }
  }
  a.Link{
    text-decoration: none !important;
  }
}

@media only screen and (max-width: 660px)  {
  .App-Footer {
    justify-content: center;
    flex-direction: column;
    text-align: justify;
  }
  .payment-overview .logo {
    text-align: center;
  }
  .Footer-Links,
  .Footer-PoweredBy {
    text-align: center;
  }
}