.payment-overview {
  .logo {
    padding-top: 16px;

    img {
      max-height: 100%;
      max-width: 100%;
      margin-right: 8px;
    }
  }

  .firm {
    padding: 45px 0 8px;

    span {
      font-size: 16px;
      font-weight: 500;
      color: #697386;
    }
  }

  .currency {
    span {
      font-size: 36px;
      font-weight: 600;
      margin: 2px 0 3px;
      color: #3c4257;
    }
  }
  .checkout_message {
    padding-top: 30px;
    span {
      color: #3c4257;
      font-size: 14px;
      font-weight: 500;
      word-break: break-word;
    }
  }

  .conditions {
    padding-top: 30px;

    span {
      font-size: 14px;
      font-weight: 500;
      color: #3c4257;
    }
  }

  .invoice-amount {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    color: #3c4257;
    line-height: 20px;
    padding-top: 30px;
  }

  .invoice-number {
    font-size: 11px;
    color: #535252;
    line-height: 20px;
  }
}
